.inputGroup {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--inner-spacing-1);
  width: 100%;
}

.inputGroup {
  max-inline-size: 100%;
  gap: var(--inner-spacing-1);
  border: 0;
  border-radius: var(--border-radius-elevation-3);
  background-color: var(--color-bg-neutral-subtle);
  box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle);
  isolation: isolate;
  /* Delete overflow: hidden; when the max height is added */
  overflow: hidden;
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  padding: 0;
  padding-block: var(--inner-spacing-1);
  padding-inline: var(--inner-spacing-2);
  box-sizing: content-box;
  cursor: inherit;
}

.inputGroup .input:not(textarea) {
  block-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end)
  );
  padding-block: var(--inner-spacing-3);
}

.inputGroup:has([data-input-prefix]) .input {
  padding-inline-start: 0;
}

.inputGroup:has([data-input-suffix]) .input {
  padding-inline-end: 0;
}

.input:is(textarea) {
  block-size: auto;
  min-block-size: var(--sizing-16);
  align-items: flex-start;
  resize: none;
  font-family: inherit;
  max-height: var(--max-height);
  padding-block: 0;
  margin-block: var(--inner-spacing-1);
}

.input:autofill,
.input:autofill:hover,
.input:autofill:focus,
.input:autofill:active {
  font-size: initial;
  -webkit-text-fill-color: var(--color-fg);
  -webkit-box-shadow: 0 0 0 40rem var(--color-bg-neutral-subtle) inset;
}

/**
 * ----------------------------------------------------------------------------
 * SUFFIX and PREFIX
 * ----------------------------------------------------------------------------
 */
.inputGroup [data-input-prefix] {
  margin-inline-start: var(--inner-spacing-2);
}

.inputGroup:has(.input[data-size="small"]) [data-input-prefix] {
  margin-inline-start: 0;
}

.inputGroup [data-input-suffix] {
  margin-inline-end: var(--inner-spacing-2);
}

.inputGroup:has(.input[data-size="small"]) [data-input-suffix] {
  margin-inline-end: 0;
}

.inputGroup :is([data-input-suffix], [data-input-prefix]) button {
  border-radius: calc(
    var(--border-radius-elevation-3) - var(--inner-spacing-1)
  );
}

.inputGroup :is([data-input-suffix], [data-input-prefix]) {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**
 * ----------------------------------------------------------------------------
 * HOVERED
 * ----------------------------------------------------------------------------
 */
.inputGroup:is([data-hovered], :has([data-hovered])):has(
    > .input:not(
        :is(
            [data-focused],
            [data-readonly],
            [data-disabled],
            [data-focus-within],
            :has(~ input[data-disabled="true"])
          )
      )
  ) {
  background-color: var(--color-bg-neutral-subtle-hover);
  box-shadow: inset 0 0 0 1px var(--color-bd-on-neutral-subtle-hover);
}

/**
 * ----------------------------------------------------------------------------
 * READONLY
 * ----------------------------------------------------------------------------
 */
.inputGroup:has(> .input[data-readonly]) {
  background-color: transparent;
  box-shadow: none;
}

.inputGroup input[data-readonly] {
  padding-inline: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
}

/** Reason for doing this is because for readonly inputs, we want focus state to be wider than the component width */
.inputGroup:has(> .input[data-readonly][data-focus-visible])::before {
  content: "";
  left: calc(-0.5 * var(--inner-spacing-1));
  width: calc(100% + var(--inner-spacing-1));
  height: 100%;
  position: absolute;
  box-shadow: 0 0 0 2px var(--color-bd-focus);
  border-radius: var(--border-radius-elevation-3);
}

/**
 * ----------------------------------------------------------------------------
 * PLACEHOLDER
 * ----------------------------------------------------------------------------
 */
.input::placeholder {
  color: var(--color-fg-neutral-subtle) !important;
  opacity: 1;
}

.input:placeholder-shown {
  text-overflow: ellipsis;
}

/**
 * ----------------------------------------------------------------------------
 * DISABLED
 * ----------------------------------------------------------------------------
 */
.inputGroup:has(
    :is(.input[data-disabled], .input:has(~ input[data-disabled]))
  ) {
  cursor: not-allowed;
  box-shadow: none;
}

/**
 * ----------------------------------------------------------------------------
 * INVALID
 * ----------------------------------------------------------------------------
 */
.inputGroup:has(> .input[data-invalid]) {
  box-shadow: 0 0 0 1px var(--color-bd-negative);
}

.inputGroup[data-hovered]:has(
    > .input[data-invalid]:not(
        :is([data-focused], [data-readonly], [data-disabled])
      )
  ) {
  box-shadow: 0 0 0 1px var(--color-bd-negative-hover);
}

/**
 * ----------------------------------------------------------------------------
 * FOCUSSED
 * ----------------------------------------------------------------------------
 */
.inputGroup:has(
    > .input:is([data-focused], [data-focus-within]):not([data-readonly])
  ) {
  background-color: transparent;
  box-shadow: 0 0 0 2px var(--color-bd-focus);
}

/**
 * ----------------------------------------------------------------------------
 * SIZE
 * ----------------------------------------------------------------------------
 */
.inputGroup .input[data-size="small"] {
  block-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end)
  );
  padding: var(--inner-spacing-2);
}

.inputGroup .input[data-size="large"] {
  padding: var(--inner-spacing-3);
}
